<template>
  <div class="message-header">
    <v-row dense v-if="model">
      <v-col cols="6" sm="3" md="3" lg="2" xl="2">
        <div class="message-header-column">
          <label class="message-header-column--title">Categoria</label>
          <span class="message-header-column--description">
            {{ model.categoryName }}
          </span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="2" xl="2">
        <div class="message-header-column">
          <label class="message-header-column--title">Assunto</label>
          <span
            class="message-header-column--description"
            :title="model.subject"
          >
            {{ doTruncateText(model.subject, 25) }}
          </span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="1" xl="1">
        <div class="message-header-column">
          <label class="message-header-column--title">Aberto a</label>
          <span class="message-header-column--description">
            {{ formatDays(model.openDays) }}
          </span>
        </div>
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        v-if="!model.isCustomerUser && isResponsible && model.creatorUser"
      >
        <div class="message-header-column">
          <label class="message-header-column--title">Cliente</label>
          <span class="message-header-column--description">
            <span :title="model.creatorUser.name">
              {{ doTruncateText(model.creatorUser.name, 15) }}
            </span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="primary"
                  small
                  @click="viewUser(model.creatorUser)"
                >
                  fa-regular fa-eye
                </v-icon>
              </template>
              <span>Visualizar informações</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="2" xl="2" v-if="model.responsibleUser">
        <div class="message-header-column">
          <label class="message-header-column--title">Responsável</label>
          <span class="message-header-column--description">
            <span :title="model.responsibleUser.name">
              {{ doTruncateText(model.responsibleUser.name, 15) }}
            </span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="primary"
                  small
                  @click="viewUser(model.responsibleUser)"
                >
                  fa-regular fa-eye
                </v-icon>
              </template>
              <span>Visualizar informações</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        v-if="!model.isDemandedUser && isResponsible && model.demandedUser"
      >
        <div class="message-header-column">
          <label class="message-header-column--title">Demandado a</label>
          <span class="message-header-column--description">
            <span :title="model.demandedUser.name">
              {{ doTruncateText(model.demandedUser.name, 15) }}
            </span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="primary"
                  small
                  @click="viewUser(model.demandedUser)"
                >
                  fa-regular fa-eye
                </v-icon>
              </template>
              <span>Visualizar informações</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
      <v-col
        cols="6"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        v-if="model.isCustomerUser && model.situation === 'Resolvido'"
      >
        <div class="message-header-column">
          <label class="message-header-column--title">
            Sua solicitação foi resolvida?
          </label>
          <span class="message-header-column--description">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  class="message-icons color-green cursor-pointer mr-3"
                  @click="isAccepted = true"
                >
                  <i class="fa-solid fa-thumbs-up"></i>
                </span>
              </template>
              <span>Aceitar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  class="message-icons color-red cursor-pointer"
                  @click="isReject = true"
                >
                  <i class="fa-solid fa-thumbs-down"></i>
                </span>
              </template>
              <span>Rejeitar</span>
            </v-tooltip>
          </span>
        </div>
      </v-col>
    </v-row>

    <DialogAccepted
      v-if="isAccepted"
      :dialog="isAccepted"
      :ticketId="model.id"
      @closeModal="closeModal"
    />

    <DialogReject
      v-if="isReject"
      :dialog="isReject"
      :ticketId="model.id"
      @closeModal="closeModal"
    />

    <DialogInfo
      v-if="isInfo"
      :dialog="isInfo"
      :model="userSelected"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { doTruncateText, getColor } from '@/helpers/utils';
import { formatDays } from '@/helpers/formatting';

export default {
  components: {
    DialogAccepted: () =>
      import('@/components/pages/supports/dialogs/dialog-accepted'),
    DialogReject: () =>
      import('@/components/pages/supports/dialogs/dialog-reject'),
    DialogInfo: () => import('@/components/pages/supports/dialogs/dialog-info')
  },

  data: () => ({
    isAccepted: false,
    isReject: false,
    isInfo: false,
    userSelected: null
  }),

  computed: {
    ...mapGetters({
      model: 'support/getById'
    }),

    isResponsible() {
      return (
        this.model.isDemandedUser === false &&
        this.model.isCustomerUser === false
      );
    }
  },

  methods: {
    doTruncateText,
    formatDays,
    getColor,

    viewUser(model) {
      this.isInfo = true;
      this.userSelected = model;
    },

    closeModal(redirect) {
      this.isAccepted = false;
      this.isReject = false;
      this.isInfo = false;
      this.userSelected = null;

      if (redirect) {
        this.$router.push({ name: 'suportes' });
      }
    }
  }
};
</script>
